<template>
<div class="login-main">
 <nav-bar></nav-bar>
  <section class="order-details-start  box-flex">
    <div class="container review-main text-capitalize position-relative  box-flex__wrapper">
      <div class="row">
        <div class="text-center col-md-12 p-0 my-2 odr-loadr" v-if="loading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <div class="col-md-12 p-0" v-for="(orders,index) in ordersDetail" :key="index">
          <div class="center-card">
            <div class="center-card-box p-3 border-bottom top-sticky">
              <div class="resturant-details order-id">
                <h4 class="text-start">{{ $t('Order') }} #{{orders.order_id}}</h4>
              </div>
            </div>
            <div class="center-card-box p-3">
              <div class="orders-inner-main">
                <div class="orders-lst">
                  <div class="order-item d-flex align-items-start mb-2 pb-2">                
                    <div class="smallimg-squre">
                        <img :src="orders.restaurant_image" />
                      </div>
                    <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                      <div class="orderid-main">
                        <h6 class="mb-0">{{orders.restaurant_name}}</h6>                    
                        <p class="mt-1" v-if="cuisineNames.length > 0">{{cuisineNames.join(', ')}}</p>
                        <a class="mt-2 d-inline-block" v-if="orders.support_contact_number && orders.support_contact_number != 'null'" :href="'tel:'+orders.support_contact_number">
                          <i class="fas fa-phone-alt"></i> {{ $t('Call') }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="inner-data">
                    <h6 class="mb-1">{{ $t('Your Order') }}</h6>
                    <div class="order-history-main" v-for="(restaurant,index) in orders.restaurant_item_details" :key="index">
                      <div class="order-histry d-flex justify-content-between align-items-start">
                        <div class="histry-name item-heading delivery-link">
                          <div class="veg" v-if="restaurant.item_type == 0">
                            <span></span>
                          </div>
                          <div class="non-veg" v-else-if="restaurant.item_type == 1">
                            <span></span>
                          </div>
                          <div v-else-if="restaurant.item_type == 2">
                            <span></span>
                          </div>
                          <!-- {{ restaurant.item_tax_value }} -->
                          <p class="mb-0">{{restaurant.restaurant_menu_item_name}}  <span v-if="restaurant.item_tax != 0">({{ (restaurant.item_tax_value) }}%) </span>:</p> 
                          <span>{{restaurant.quantity}} {{ $t('item') }}</span>
                          <div class="oderoptions" v-if="restaurant.custmisation.length > 0">
                            <span v-for="(subtype,i) in restaurant.custmisation" :key="i">{{subtype.type_name}}
                              <span v-if="subtype.option.length > 0">
                                <span v-for="(option,ii) in subtype.option" :key="ii">[{{option.selection_name}}]
                                  <span v-if="option.options.length > 0">
                                    <span v-for="(sub,iii) in option.option" :key="iii">
                                      {{sub.selection_name}}
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>                        
                        </div>
                        <div class="histry-price">
                          <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(restaurant.amount).toFixed(2)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="billing-data border-0 mt-2" v-if="orders.notes != ''">
                      <h6 class="mb-2">{{ $t('Special Instructions') }}</h6>
                      <p>{{orders.notes}}</p>
                    </div>
                    <div class="billing-data border-0 mt-2">
                      <h6 class="mb-2">{{ $t('Billing Details') }}</h6>
                      <ul>
                        <li class="d-flex justify-content-between">
                          <span v-if="orders.item_tax > '0'"> {{ $t("Item Total") }}<i v-b-tooltip.hover.right.nofade.html.v-secondary=" 'Included item ' + ($auth.setting.terminology && $auth.setting.terminology[0].tax_name !== '' ? $auth.setting.terminology[0].tax_name : '') + ' ' + $auth.setting.currency + ' ' + orders.item_tax" class="fa fa-info-circle" aria-hidden="true"></i></span>
                          <span v-else>{{$t('Item Total')}}</span>
                          <div><span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.sub_total).toFixed(2)}}</span></div>
                        </li>
                        <li class="d-flex justify-content-between" v-if="orders.packaging_charge > 0">
                          <span>{{$t('Packaging Charge')}}</span>
                          <div><span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.packaging_charge).toFixed(2)}}</span></div>
                        </li>
                        <li class="d-flex justify-content-between" v-if="orders.delivery_fee > 0">
                          {{ $t('Delivery Charges') }}
                          <div>
                            <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.delivery_fee).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between" v-if="orders.service_fee >0">
                          {{$auth.setting && $auth.setting.terminology && $auth.setting.terminology[0].tax_name}}
                          <div>
                            <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.service_fee).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between" v-if="orders.convince_charge > 0">
                          {{ $t('Convenience Charges') }}
                          <div>
                            <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.convince_charge).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between"  v-for="tax in taxes" 
                        :key="tax.tax_value" v-if="tax.name != 0">
                        {{ $t("Include item") }} {{ $auth.setting.tax_name_primary_lang }} ({{ parseFloat(tax.name).toFixed(2) }}%) 
                        <span>
                          <span v-html="$auth.setting.currency" class="currency"></span>
                          {{ parseFloat(tax.value).toFixed(2) }}
                        </span>
                      </li>


                        <li class="d-flex justify-content-between" v-if="orders.driver_tip > 0">
                          {{ $t('Driver Tip') }}
                          <div>
                            <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.driver_tip).toFixed(2)}}</span>
                          </div>
                        </li>
                        
                        <li class="d-flex justify-content-between discount-price" v-if="orders.discount_price">
                          {{ $t('Discount') }}
                          <div> -<span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.discount_price).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between promo-discount" v-if="orders.cashback">
                          {{ $t('Cashback') }}
                          <div class="promo-discount"> <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.cashback).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between bold">
                          {{ $t('Total Paid') }}
                          <div>
                            <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.total_amount).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between walltline" v-if="orders.wallet_amount > 0">
                          {{ $t('Wallet') }}
                          <div class="walltline">
                            - <span v-html="$auth.setting.currency" class="currency"></span><span>{{parseFloat(orders.wallet_amount).toFixed(2)}}</span>
                          </div>
                        </li>
                        <li class="d-flex justify-content-between bold">
                          {{$t('You Saved')}}
                          <div class="svprc">
                            <span v-html="$auth.setting.currency" class="currency"></span> <span>{{parseFloat(orders.saved_amount).toFixed(2)}}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="billing-data border-0 mt-2">
                      <h6 class="mb-2">{{ $t('Order Details') }}</h6>
                      <ul>
                        <li class="d-flex justify-content-between">
                          {{ $t('Order Number') }}
                          <span>#{{orders.order_id}}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                          {{ $t('Placed Date') }}
                          <span v-if="$auth.setting && $auth.setting.date_format == 'm-d-y H:i'">{{moment(orders.order_date).format('MMMM-D-YYYY H:mm')}}</span>
                          <span v-if="$auth.setting && $auth.setting.date_format == 'd-m-y H:i'">{{moment(orders.order_date).format('D-MM-YYYY H:mm')}}</span>
                          <span v-if="$auth.setting && $auth.setting.date_format == 'm-d-y h:i A'">{{moment(orders.order_date).format('MMM D YYYY h:mm A')}}</span>
                          <span v-if="$auth.setting && $auth.setting.date_format == 'd-m-y h:i A'">{{moment(orders.order_date).format('D-MM-YYYY h:mm A')}}</span>                      
                        </li>
                        <li class="d-flex justify-content-between">
                          {{ $t('Order Type') }}
                          <span>{{$t(orders.delivery_pickup_types)}}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                          {{ $t('Payment Mode') }}
                          <span v-if="orders.payment_method == 'Cash On Delivery'">
                            {{orders.payment_type != 'wallet'?''+$t(orders.payment_type)+'':''}}
                          </span>
                          <span v-else>
                            {{$t(orders.payment_method)}}{{orders.payment_type != 'wallet'?' ('+$t(orders.payment_type)+')':''}}
                          </span>
                        </li>
                        <li class="d-flex justify-content-between delivery-locality">
                          {{ $t('Delivery Address') }}
                          <span>{{orders.delivery_address}}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="orders-inner-main" v-if="orders.order_images.length > 0">
                <h4>{{$t('Order Image')}}</h4>
                <div class="container gallery-container">
                  <div class="tz-gallery">
                    <div class="row">
                      <div class="col-md-3 pb-2" v-for="img in orders.order_images" :key="img.i" >
                        <div class="bg-image hover-overlay ripple shadow-1-strong" data-ripple-color="light">
                          <img :src="img.image" style="width:100%;cursor:pointer" @click="openImg(img)" class="w-100">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="past" v-if="orders.cancelled_desc" class="tab-pane tab-box cncel-ordr">
                <h6>{{ $t('Cancelled Order') }}</h6>
                <p>{{orders.cancelled_desc}}</p>
              </div>
            </div>
          </div>
        </div>        
        <div class="order-offers" v-if="ordersDetail.length == 0 && !loading">
          <p class="text-center">{{ $t('No Order Found') }}</p>
        </div>
      </div>
    </div>
  </section>
  <div class="modal fade order-img" data-backdrop="static" data-keyboard="false" id="imgOpen" tabindex="-1" aria-labelledby="imgOpen" aria-hidden="true" ref="imgOpenModal" style="z-index: 9999;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content order-img-modal">
          <div class="modal-body text-center">
            <a data-bs-dismiss="modal" class="lnk crosclose">X</a>
            <img :src="imageUrl">
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from "moment";
import Footer from '../components/Footer.vue';
export default {
    name:'orderdetails',
    components: {NavBar,Footer},
    data(){
      return{
        ordersDetail: [],
        taxes: [],
        moment: moment,
        loading: true,
        cuisineNames:[],
        imageUrl:''
      }
    },
    computed:{
      ...mapGetters("order",["orderDetail"]),
    },
    mounted(){
      setTimeout(() => {        
        if(this.$auth.user){
          this.orderDetails();
        }else{
          this.$router.push({ name: "sign-in" });
        }
      }, 1000);
    },
    methods: {
    ...mapActions("order",["viewOrderDetail"]),
    orderDetails(){
      this.loading = true;
      if(this.isBase64(this.$route.params.order_id)) {
        this.viewOrderDetail({
          vendor_id: this.$auth.getVendorId(),
          order_id: this.base64_decode(this.$route.params.order_id),
          is_langauge : this.$store.state.lang.locale
        }).then(data => {
          if (data.code == 200) {
            this.loading = false;
            this.ordersDetail = data.Result;
            this.taxes = this.ordersDetail[0].taxes;
            let cusion = [];
            this.ordersDetail[0].cuisine_name.forEach((cuisineName)=>{
              if(cuisineName != ''){
                cusion.push(cuisineName);
              }              
            })
            this.cuisineNames = cusion;              
          }else{
            this.loading = false;
            this.ordersDetail = [];
          }
        })
      }else {
        this.loading = false;
        this.ordersDetail = [];
      }
    },

    isBase64(str) {
      if (str ==='' || str.trim() ===''){ return false; }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
    },

    openImg(element){      
      this.imageUrl = element.image;
      $("#imgOpen").modal().show();
    }
  },   
};
</script>
<style scoped>
.order-img-modal{
  height: unset;
}
.modal-body.text-center {
  padding: 10px;
  /* padding-top: 0px; */
  margin-bottom: 0px;
}
.bg-image img {
  border-radius: 10px;
}
</style>